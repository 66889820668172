<template>
  <div class="page page--statuses">
    <esmp-table-wrapper
      title="Управление статусами"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <esmp-table
        :columns="columns"
        :rows="rows"
        :loading="isLoading"
        stripe
      >
        <template #cell-name="{ tr }">
          <span>{{ tr.name }}</span>
        </template>

        <template #cell-msTranslatedName="{ tr }">
          <span>{{ tr.msTranslatedName }}</span>
        </template>

        <template #cell-translatedName="{ tr }">
          <esmp-editable-text
            v-model="tr.translatedName"
            clearable
            @input="onStatusChange(tr.id, 'translatedName', $event)"
          />
        </template>

        <template #cell-color="{ tr }">
          <esmp-color-picker
            v-model="tr.color"
            without-text-input
            @input="debouncedOnStatusChange(tr.id, 'color', $event)"
          />
        </template>
      </esmp-table>
    </esmp-table-wrapper>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { DEFAULT_SOURCE_SYSTEM } from '@/constants/global';

export default {
  name: 'Statuses',

  data() {
    return {
      isLoading: false,

      columns: Object.freeze([
        {
          title: 'Название SM (англ)',
          key: 'name',
        },
        {
          title: 'Название SM (русский)',
          key: 'msTranslatedName',
        },
        {
          title: 'Спец. название на портале',
          key: 'translatedName',
        },
        {
          title: 'Цвет',
          key: 'color',
          width: '56px',
        },
      ]),

      rows: [],
    };
  },

  methods: {
    async getStatuses() {
      try {
        this.isLoading = true;
        const { data: statuses } = await this.$API.admin.getStatuses();

        this.rows = statuses;
      } finally {
        this.isLoading = false;
      }
    },

    async onStatusChange(id, property, value) {
      const data = {
        [property]: value,
      };

      await this.$API.admin.setStatus(id, data);
    },
  },

  created() {
    this.getStatuses();

    this.debouncedOnStatusChange = debounce(this.onStatusChange, DEFAULT_SOURCE_SYSTEM);
  },
};
</script>
